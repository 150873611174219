import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBaseControlComponent } from './base';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AvaSkeletonComponent } from '../../ava-skeleton';

@Component({
  selector: 'ava-form-input',
  template: `
    <nz-form-item class="w-full" [ngClass]="{ 'hide-element': showMask, 'ava-label-hide': !formLabel }">
      <nz-form-label class="ava-form-label" [nzFor]="controlId">
        {{ formLabel || placeholder }}
      </nz-form-label>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-input-group [nzSuffix]="suffixRef" [nzAddOnBefore]="beforeLabel" [nzAddOnAfter]="afterLabel">
          <input
            type="text"
            nz-input
            [name]="formControlName"
            [formControl]="formControl"
            [placeholder]="placeholder"
            [attr.id]="controlId"
            class="ava-form-control"
            (focusin)="showErrors = false"
            (focusout)="showErrors = true"
          />
        </nz-input-group>

        <ng-template #errors let-control>
          @if (formControl?.errors && showErrors) {
            @if ((formControl.errors | keyvalue)[0]; as error) {
              <div
                class="ava-form-error"
                [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
              ></div>
            }
          }
        </ng-template>
        <ng-template #suffixRef>
          {{ formControl.value && showSuffix ? suffixLabel || placeholder : '' }}
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    @if (showMask) {
      <ava-skeleton isLoading="true" isActive="true" />
    }
  `,
  styles: [
    `
      @import '../../../../styles/ava-responsive';

      :host {
        font-weight: 200;
        font-family: var(--ava-font-family);

        input::placeholder,
        ::ng-deep .ant-input-suffix {
          @include formControlPlaceholderStyle();
        }

        .hide-element {
          display: none;
        }

        .ava-label-hide nz-form-label {
          width: 0;
          overflow: hidden;
          position: absolute;
        }

        .ava-form-control {
          @include formControlInputFontStyle();
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    ReactiveFormsModule,
    NzIconModule,
    AvaSkeletonComponent,
  ],
  standalone: true,
})
export class FormInputComponent extends FormBaseControlComponent {
  @Input() showMask = false;
  @Input() showSuffix = true;
  @Input() suffixLabel = '';
  @Input() beforeLabel = '';
  @Input() afterLabel = '';
}
